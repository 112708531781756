<template>

  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показ</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>строка</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refSavedListTable"
          class="position-relative"
          :items="fetchSaveds"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Записи не найдены"
          :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Image -->
        <template #cell(image)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                  width="120"
                  :src="data.item.path ? 'https://edu.startupchoikhona.tj/backend/'+data.item.path : ''"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: student -->
        <template #cell(student)="data">
          <span class="align-text-top">{{ data.item.user ? data.item.user.name : '' }}</span>
        </template>

        <!-- Column: DateTime -->
        <template #cell(created_at)="data">
            <span class="align-text-top">{{ data.item.created_at }}</span>
        </template>

        <!-- Column: Course -->
        <template #cell(course)="data">
            <span class="align-text-top">{{ data.item.course ? data.item.course.title : '' }}</span>
        </template>

        <!-- Column: Module -->
        <template #cell(module)="data">
            <span class="align-text-top">{{ data.item.module ? data.item.module.name : '' }}</span>
        </template>

        <!-- Column: Mentor -->
        <template #cell(instructor)="data">
            <span class="align-text-top">{{ data.item.course && data.item.course.user ? data.item.course.user.name : ''}}</span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <feather-icon
                @click="showCertificate(`https://edu.startupchoikhona.tj/backend${data.item.path}`)"
                icon="EyeIcon"
                class="cursor-pointer mr-1"
                size="24"
            />
          <a class="" :href="`https://edu.startupchoikhona.tj/backend${data.item.path}`" download>
            <feather-icon
                icon="DownloadIcon"
                size="24"
            />
          </a>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} строк</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalSaveds"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-lg"
        size="lg"
        centered
        ok-only
        ok-title="Закрыть"
        v-model="modalShow"
    >
      <b-img :src="activeCertificatePath" class="w-100"></b-img>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BImg, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useCertificatesList from './useCertificatesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BModal,
    vSelect,
  },
  data() {
    return {
      modalShow: false,
      activeCertificatePath: '',
    }
  },
  methods: {
    showCertificate(path) {
      this.activeCertificatePath = path
      this.modalShow = true
    },
  },
  setup() {
    const savedID = ref(0)

    const {
      fetchSaveds,
      deleteSaved,
      tableColumns,
      perPage,
      currentPage,
      totalSaveds,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSavedListTable,
      refetchData,

    } = useCertificatesList()

    return {

      fetchSaveds,
      deleteSaved,
      tableColumns,
      perPage,
      currentPage,
      totalSaveds,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSavedListTable,
      refetchData,

      // Filter
      avatarText,

      savedID,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
